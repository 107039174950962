<template>
  <v-container>
    <v-card class="mx-auto text-center" flat>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <div class="text-h4 font-weight-thin">MPG</div>
              <v-sheet color="rgba(0, 0, 0, .05)">
                <v-sparkline :value="mpgLastFive" smooth line-width="2" auto-draw padding="12">
                  <template v-slot:label="item"> {{ item.value }} </template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-subtitle1">
                Last 5: {{ formatNumber(arrayAverage(mpgLastFive), 2) }} | Average:
                {{ formatNumber(arrayAverage(mpg), 2) }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <div class="text-h4 font-weight-thin">Cost per Tank</div>
              <v-sheet color="rgba(0, 0, 0, .05)">
                <v-sparkline :value="costPerTankLastFive" smooth line-width="2" auto-draw padding="12">
                  <template v-slot:label="item"> {{ item.value }} </template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-subtitle1">
                Last 5: {{ currencyFormat(arrayAverage(costPerTankLastFive), 2) }} | Average:
                {{ currencyFormat(arrayAverage(costPerTank), 2) }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="currentLog"
          :items-per-page="5"
          :options="{ sortBy: ['date'], sortDesc: [true] }"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.mpg`]="{ item }"> {{ formatNumber(item.mpg / 100) }} </template>
          <template v-slot:[`item.cpm`]="{ item }"> {{ currencyFormat(item.cpm / 100) }} </template>
          <template v-slot:[`item.price`]="{ item }"> {{ currencyFormat(item.price / 100) }} </template>
          <template v-slot:[`item.cost`]="{ item }"> {{ currencyFormat(item.cost / 100) }} </template>
          <template v-slot:[`item.miles`]="{ item }"> {{ formatNumber(item.miles) }} </template>
          <template v-slot:[`item.gallons`]="{ item }"> {{ formatNumber(item.gallons / 1000) }} </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import numberFormat from '@/shared/mixins/numberFormat'
import API from '@/shared/enums/api'

export default {
  name: 'VehicleTrackerFuelLog',
  mixins: [numberFormat],
  data() {
    return {
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'MPG', value: 'mpg' },
        { text: 'CPM', value: 'cpm' },
        { text: 'Price', value: 'price' },
        { text: 'Cost', value: 'cost' },
        { text: 'Miles', value: 'miles' },
        { text: 'Gallons', value: 'gallons' }
      ]
    }
  },
  computed: {
    ...mapState({
      currentVehicle: (state) => state.vehicle.currentVehicle,
      vehicleApi: (state) => state.vehicle.vehicleApi
    }),
    ...mapGetters('vehicle', ['vehicleFuelLogs', 'vehicle']),
    loading() {
      return this.vehicleApi === API.LOADING
    },
    currentLog() {
      const result = this.vehicleFuelLogs(this.currentVehicle)
      return result
    },
    mpg() {
      const result = this.currentLog.map((log) => {
        return log.mpg / 100
      })
      return result
    },
    mpgLastFive() {
      const result = this.mpg.slice(Math.max(this.mpg.length - 5, 0))
      return result
    },
    costPerTank() {
      const result = this.currentLog.map((log) => {
        return log.cost / 100
      })
      return result
    },
    costPerTankLastFive() {
      const result = this.costPerTank.slice(Math.max(this.mpg.length - 5, 0))
      return result
    }
  }
}
</script>

<style></style>
