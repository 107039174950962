<template>
  <v-container>
    <v-card elevation="8" outlined>
      <v-card-title class="white--text grey darken-3"> View Log </v-card-title>
      <v-card-text>
        <v-container class="mt-6">
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <v-btn-toggle v-model="formSelection" mandatory color="grey darken-3" class="justify-center">
                <v-btn block> Fuel </v-btn>
                <v-btn block> Service </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <select-vehicle />
        </v-container>
      </v-card-text>
      <div v-if="vehicle(currentVehicle).length === 1">
        <v-card-text class="text-center">
          <div class="text-h4 font-weight-thin">Mileage</div>
          <div class="text-h5 font-weight-thin">{{ vehicle(currentVehicle)[0].current_mileage }}</div>
        </v-card-text>
        <v-tabs-items v-model="formSelection">
          <v-tab-item :key="0">
            <fuel-log></fuel-log>
          </v-tab-item>
          <v-tab-item :key="1">
            <service-log></service-log>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import FuelLog from '../../components/vehicleTracker/VehicleTrackerLogFuel.vue'
import ServiceLog from '../../components/vehicleTracker/VehicleTrackerLogService.vue'
import SelectVehicle from '../../components/vehicleTracker/VehicleTrackerLayoutSelectVehicle.vue'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'VehicleTrackerLogs',
  components: {
    FuelLog,
    ServiceLog,
    SelectVehicle
  },
  data() {
    return {
      formSelection: 0,
      selectedVehicle: null
    }
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.vehicle.vehicles,
      currentVehicle: (state) => state.vehicle.currentVehicle,
      fuelLogs: (state) => state.vehicle.fuelLogs,
      serviceLogs: (state) => state.vehicle.serviceLogs
    }),
    ...mapGetters('vehicle', ['vehicle']),
    rules() {
      return {
        required: (value) => !!value || 'Required',
        greaterThanCurrent: (value) => value > this.currentVehicle.current_mileage || 'Check mileage'
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('vehicle', ['fetchVehicles', 'updateCurrentVehicle', 'fetchFuelLogs', 'fetchServiceLogs']),
    async getData() {
      if (this.vehicles.length === 0) {
        this.fetchVehicles()
      }
      if (localStorage.currentVehicle) {
        this.updateCurrentVehicle(localStorage.currentVehicle)
        this.selectedVehicle = this.currentVehicle
      }
      if (this.fuelLogs.length === 0) {
        this.fetchFuelLogs()
      }
      if (this.serviceLogs.length === 0) {
        this.fetchServiceLogs()
      }
    }
  }
}
</script>

<style></style>
