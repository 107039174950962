var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mx-auto text-center",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-h4 font-weight-thin"},[_vm._v("MPG")]),_c('v-sheet',{attrs:{"color":"rgba(0, 0, 0, .05)"}},[_c('v-sparkline',{attrs:{"value":_vm.mpgLastFive,"smooth":"","line-width":"2","auto-draw":"","padding":"12"},scopedSlots:_vm._u([{key:"label",fn:function(item){return [_vm._v(" "+_vm._s(item.value)+" ")]}}])})],1)],1),_c('v-card-text',[_c('div',{staticClass:"text-subtitle1"},[_vm._v(" Last 5: "+_vm._s(_vm.formatNumber(_vm.arrayAverage(_vm.mpgLastFive), 2))+" | Average: "+_vm._s(_vm.formatNumber(_vm.arrayAverage(_vm.mpg), 2))+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-h4 font-weight-thin"},[_vm._v("Cost per Tank")]),_c('v-sheet',{attrs:{"color":"rgba(0, 0, 0, .05)"}},[_c('v-sparkline',{attrs:{"value":_vm.costPerTankLastFive,"smooth":"","line-width":"2","auto-draw":"","padding":"12"},scopedSlots:_vm._u([{key:"label",fn:function(item){return [_vm._v(" "+_vm._s(item.value)+" ")]}}])})],1)],1),_c('v-card-text',[_c('div',{staticClass:"text-subtitle1"},[_vm._v(" Last 5: "+_vm._s(_vm.currencyFormat(_vm.arrayAverage(_vm.costPerTankLastFive), 2))+" | Average: "+_vm._s(_vm.currencyFormat(_vm.arrayAverage(_vm.costPerTank), 2))+" ")])])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.currentLog,"items-per-page":5,"options":{ sortBy: ['date'], sortDesc: [true] },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.mpg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.mpg / 100))+" ")]}},{key:"item.cpm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.cpm / 100))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.price / 100))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.cost / 100))+" ")]}},{key:"item.miles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.miles))+" ")]}},{key:"item.gallons",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.gallons / 1000))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }